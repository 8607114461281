import lancome_handle from "../../assets/img/lancome_handle.svg";
import lancome_trash from "../../assets/img/lancome_trash.svg";

import CONSTANTS from "../../lib/Constants";

import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: ["game", "totalLiveGames"],
  data() {
    return {
      lancome_handle,
      lancome_trash,
      showResults: this.game.showResults ? true : false,
      dataGame: [],
      gameObject: {
        id: "",
        type: "",
        result: false,
      },
    };
  },
  computed: {
    renderOptions() {
      if (
        this.game &&
        this.game.results &&
        this.game.results.options &&
        Array.isArray(this.game.results.options)
      ) {
        return this.game.results.options;
      }

      return [];
    },

    gameStatus() {
      let status = "pending";
      if (this.game && this.game.status) {
        switch (this.game.status) {
          case "live":
          case "ended":
            status = this.game.status;
        }
      }
      return status;
    },

    totalCorrect() {
      let total = 0;
      try {
        total = this.game.results.options.reduce((total, el) => {
          if (el.isCorrect && el.votes > 0) {
            return total + el.votes;
          } else {
            return total;
          }
        }, 0);
      } catch {
        total = 0;
      }
      return total;
    },
    totalIncorrect() {
      let total = 0;

      try {
        total = this.game.results.options.reduce((total, el) => {
          if (!el.isCorrect && el.votes > 0) {
            return total + el.votes;
          } else {
            return total;
          }
        }, 0);
      } catch {
        total = 0;
      }
      return total;
    },
    totalResults() {
      return this.totalCorrect + this.totalIncorrect;
    },
  },
  methods: {
    handleDelete() {
      this.$emit("deletegame", this.game);
    },
    handleDownloadGame(game) {

      let url_id = game._id;

      axios
        .request({
          url: CONSTANTS.API_MONGO_BASE_URL + 'api/reports/games/' + url_id,
          method: "GET",
          headers: { "x-token-auth": localStorage.getItem("token") },
          responseType: "blob",
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Quiz.xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        });

    },
    sortResults() {
      // let result = this.game.options.map((op) => {
      //     let results = this.game.results.options;
      //     let result = results.find((res)=>{
      //         return res._id == op._id;
      //     });
      // });
    },
    handleGoLive() {
      this.$emit("golive", this.game);
      //this.$set(this.game,'status','live');
    },
    handleEndGame() {
      this.$emit("endgame", this.game);
      //this.$set(this.game,'status','ended')
    },


    handleShowHideResults(game) {
      //console.log(game)
      if (game.showResults) {
        if (!game.status || game.status !== "ended") {
          this.$root.$emit("changeShowresult", { id: game._id, showResults: false });
        } else if (game.status === "ended") {
          Swal.fire({
            customClass: {
              title: "title-gameEnded",
              htmlContainer: "text-gameEnded",
              confirmButton: "confirmButton-gameEnded",
              cancelButton: "cancelButton-gameEnded",
            },
            title: "Confirmed!",
            text: "Are you sure you want show the game results?",
            confirmButtonText: "Accept",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Not",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                customClass: {
                  title: "title-success",
                  confirmButton: "confirmButton-success",
                },
                icon: "success",
                title: "Success!",
                confirmButtonText: "Ok",
                confirmButtonColor: "#3085d6",
              });
              this.$root.$emit("changeShowresult", { id: game._id, showResults: true, type: game.type });
              this.$emit('showhideresults',this.game);
            } else {
              this.$root.$emit("changeShowresult", { id: game._id, showResults: false, mark:'not' });
            }
          });
        }
      } else if(game.showResults === false) {
        this.$root.$emit("changeShowresult", { id: game._id, showResults: false, mark:'not' });
        this.$emit('showhideresults',this.game);

      }

    },
  },
  watch: {
    game() {
      if (this.game != null) {
        this.sortResults();
      }
    },
    "game.showResults"() {
      this.showResults = this.game.showResults ? true : false;
    },
  },
};
