import Game from "../../components/Game/Game.vue";
import TextInput from "../../components/TextInput/TextInput.vue";
import draggable from "vuedraggable";
import lancome_handle from "../../assets/img/lancome_handle.svg";
import lancome_trash from "../../assets/img/lancome_trash.svg";
import lancome_answer_correct from "../../assets/img/lancome_answer_correct.svg";
import lancome_answer_default from "../../assets/img/lancome_answer_default.svg";
import lancome_create_game from "../../assets/img/lancome_create_game.svg";
import GameModel from "../../lib/GameModel";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";

import Swal from "sweetalert2";

export default {
  components: {
    Game,
    TextInput,
    draggable,
  },
  props: ["event"],

  data() {
    return {
      isSocketConnected: false,
      socketMessage: "",

      showEndevent: null,
      showIntermission: null,
      showRatings: null,
      lancome_handle,
      lancome_trash,
      lancome_answer_correct,
      lancome_answer_default,
      lancome_create_game,
      games: [
        // {
        //     "type": "poll",
        //     "showResults": false,
        //     "active": true,
        //     "_id": "5ff25f515e32b777b7144837",
        //     "event": "5fec1650d7c8987621a30c6b",
        //     "question": "What's your favorite color?",
        //     "position": 1,
        //     "options": [
        //         {
        //             "isCorrect": false,
        //             "active": true,
        //             "_id": "5ff25ffe5e32b777b7144838",
        //             "answer": "Yellow & Blue",
        //             "position": 1
        //         },
        //         {
        //             "isCorrect": false,
        //             "active": true,
        //             "_id": "5ff25ffe5e32b777b7144839",
        //             "answer": "Green",
        //             "position": 2
        //         }
        //     ],
        //     "createdAt": "2021-01-04T00:20:33.617Z",
        //     "__v": 0
        // }
      ],
      lastPoll: {},
      renderComponent: true,
      showCreateDialog: false,
      showConfirmDialog: false,
      confirmDialogTitle: "Do you want to proceed?",
      confirmDialogBody: "This action cannot be undone",
      confirmYesCallback: null,
      confirmNoCallback: null,
      mode: "create",
      // newEvent:{type:'trivia'}
      newEvent: {
        type: "trivia",
        question: "",
        answers: [
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
        ],
      },
      gameResults: [],
      socket: io(CONSTANTS.SOCKET_SERVER_URL, {
        transports: ["websocket", "polling"],
      }),
      allowEmit: true,
      initialized: false,
      timer: null,
      forceRecomputeCounter: 0,
    };
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    showresult(data) {
      if (data.mark == "not") {
        console.log(data);
      } else if (data.showResults) {
        for (let i = 0; i < this.games.length; i++) {
          if (this.games[i].type == data.type && this.games[i].id != data.id) {
            if (this.games[i].showResults) {
              data.showResults = false;
              Swal.fire({
                customClass: {
                  htmlContainer: "text-class-warning",
                  confirmButton: "confirm-gamePending-warning",
                },
                icon: "warning",
                text: "There are other results displaying at the moment, please end them and try again!",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1976d2",
              });
              break;
            }
          }
        }
      } else if (!data.showResults) {
        Swal.fire({
          customClass: {
            htmlContainer: "text-class",
            confirmButton: "confirm-gamePending",
          },
          icon: "warning",
          text: "To show the results the game should be ended!",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1976d2",
        });
      }

      this.games.map((g) => {
        if (g._id === data.id) {
          g.showResults = data.showResults;
        }
        this.forceRerender();
        //console.log('data',g)
        return g;
      });
    },

    recomputeRating() {
      this.forceRecomputeCounter++;
    },

    async initialize() {
      try {
        setTimeout(() => {
          this.registerEvents();
        }, 500);
        if (!this.event) {
          return;
        }
        if (this.event.intermission) {
          this.showIntermission =
            this.event.intermission === "live" ? true : false;
        }
        if (!this.socket) {
          this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
            transports: ["websocket", "polling"],
          });
        }

        await this.getEventGames();
        this.showRatings =
          this.eventRatingObj && this.eventRatingObj.status === "live"
            ? true
            : false;

        setTimeout(() => {
          this.registerEvents();
        }, 500);
      } catch (err) {
        console.error(err);
      } finally {
        this.initialized = true;
      }
    },

    async getEventGames(show = true) {
      try {
        if (!this.event._id) {
          return;
        }
        if (show) {
          this.$emit("show_loader");
        }

        let response = await GameModel.getEventGames(this.event._id);

        //TODO: pagination
        if (Array.isArray(response.data.items)) {
          let tempGamesList = response.data.items || [];
          //Get games results
          let resultsResponse = await GameModel.getGameResults(
            response.data.items
          );

          if (resultsResponse) {
            this.gameResults = resultsResponse;
          } else {
            this.gameResults = [];
          }

          //Filter only 'poll' or 'trivia'
          if (!Array.isArray(tempGamesList)) {
            tempGamesList = [];
          }

          tempGamesList = tempGamesList
            .filter((res) => {
              try {
                switch (res.type.trim().toLowerCase()) {
                  case "poll":
                  case "trivia":
                  case "quiz":
                    return true;
                  case "rating":
                    if (res._id === this.event.rating) {
                      return true;
                    }
                }
              } catch (e) {
                console.log(e);
              }
            })
            .filter((el) => el != null);

          //Assign results to game
          let aux = tempGamesList.map((game) => {

            // console.log('current game', game);

            //Sort options by position
            if (game.options.length > 0) {
              game.options.sort((a, b) => a.position - b.position);
            }

            if (!Array.isArray(resultsResponse)) {
              console.log(game);
              resultsResponse = [];
            }

            let availableResults = resultsResponse.filter((res) => {
              return res.data._id === game._id;
            });

            // console.log('Available Results', availableResults);

            availableResults = availableResults.map((res) => res.data);

            if (availableResults.length > 0) {

              availableResults = availableResults[0];

              game.options = game.options.map((gameOption) => {

                let value = availableResults.options.find((ar) => {
                  return ar._id === game.options[0]._id;
                });

                if (value) {
                  gameOption.votes = value.votes || 0;
                  gameOption.score = value.score || 0;
                } else {
                  gameOption.votes = 0;
                  gameOption.score = 0;
                }

                return gameOption;

              });

              game.results = availableResults;

            } else {

              availableResults = null;
              game.results = [];

            }

            return game;
          });

          // console.log('......');
          // console.log(aux[0].options.reduce( function (acc, obj) { return acc + obj.votes; }, 0), aux[0].results.total);
          // console.log(aux[1].options.reduce( function (acc, obj) { return acc + obj.votes; }, 0), aux[1].results.total);
          // console.log(aux[2].options.reduce( function (acc, obj) { return acc + obj.votes; }, 0), aux[2].results.total);
          // console.log('......');


          //Assign to variable
          this.games = aux;
        } else {
          this.games = [];
          this.gameResults = [];
        }
      } catch (e) {
        alert(e);
      } finally {
        this.$emit("hide_loader");
      }
    },
    showCreateNewGame() {
      this.mode = "create";
      this.newEvent = {
        type: "trivia",
        question: "",
        options: [
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
        ],
      };

      this.showCreateDialog = !this.showCreateDialog;
    },
    async createNewGame() {
      try {
        this.showCreateDialog = false;
        this.$emit("show_loader");

        //Bind event id
        this.newEvent.event = this.event._id;
        //Create editable event instance object
        let eventObjCopy = { ...this.newEvent };
        eventObjCopy.options = this.validAnswers.map((ans, index) => {
          ans.position = index;
          return ans;
        });

        // console.log('Game Save');
        // console.log(eventObjCopy);

        //Store to DB
        let savedEvent = await GameModel.save(eventObjCopy);
        eventObjCopy._id = savedEvent.data._id;

        //Store question answers
        await GameModel.saveAnswers(eventObjCopy);
        // console.log(savedEvent2);

        // console.log('Game Answers');
        // console.log(eventObjCopy);

        //Delete answers by demand
        let markedAnswersForDelete = this.deletedAnswers;

        if (markedAnswersForDelete.length > 0) {
          await GameModel.deleteAnswers(
            eventObjCopy._id,
            markedAnswersForDelete
          );
          // console.log(result);
        }

        //this.games.push(this.newEvent);
        this.getEventGames();
      } catch (e) {
        //console.log(e);
        alert(e);
      } finally {
        this.$emit("hide_loader");
      }
    },
    changeEventType(type) {
      this.$set(this.newEvent, "type", type);
    },
    addNewAnswerToQuestion() {
      // this.showCreateDialog = false;
      let answers = this.newEvent.options;
      let answersCopy;
      if (!Array.isArray(answers)) {
        answers = [
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
          {
            isCorrect: false,
            active: true,
            _id: null,
            answer: "",
            position: null,
            votes: 0,
            score: 0,
          },
        ];
      }
      answersCopy = [...answers];
      let newAnswer = {
        isCorrect: false,
        active: true,
        _id: null,
        answer: "",
        position: null,
        votes: 0,
        score: 0,
      };
      answersCopy.push(newAnswer);

      this.$set(this.newEvent, "options", answersCopy);
    },

    confirmInput(result) {
      // alert('confirminput '+ result)
      this.showConfirmDialog = false;
      if (result && typeof this.confirmYesCallback === "function") {
        this.confirmYesCallback();
      } else if (!result && typeof this.confirmNoCallback === "function") {
        this.confirmNoCallback();
      }
    },
    configureConfirmDialog({ title, body, yescb = null, nocb = null }) {
      this.confirmDialogTitle = title;
      this.confirmDialogBody = body;
      this.confirmYesCallback = yescb;
      this.confirmNoCallback = nocb;
    },
    displayConfirmDialog(config) {
      return new Promise((resolve) => {
        this.showConfirmDialog = true;
        config.yescb = () => {
          resolve(true);
        };
        config.nocb = () => {
          resolve(false);
        };
        this.configureConfirmDialog(config);
      });
    },

    async handleDelete(game) {
      try {
        //Bind event id
        this.newEvent.event = this.event._id;
        let userConfirmed = await this.displayConfirmDialog({
          title: "Please confirm",
          body: "Do you want to delete this item? This action cannot be undone.",
        });
        if (!userConfirmed) {
          return;
        }
        this.$emit("show_loader");

        //Delete from DB
        // console.log(game);
        await GameModel.deleteGame(game);

        this.getEventGames();
      } catch (e) {
        //console.log(e);
        alert(e);
      } finally {
        this.$emit("hide_loader");
      }
    },

    handleEdit(game) {
      console.log(game);
      this.mode = "edit";
      this.newEvent = {};
      this.showCreateDialog = true;
      this.newEvent = { ...game };
      console.log(this.newEvent);
    },

    async handleEndGame(game) {
      try {
        //Bind event id
        this.newEvent.event = this.event._id;
        let userConfirmed = await this.displayConfirmDialog({
          title: "Please confirm",
          body: "Do you want to end this game? This action cannot be undone.",
        });
        if (!userConfirmed) {
          return;
        }
        this.$emit("show_loader");

        //Update status in DB
        // console.log(game);
        await GameModel.updateStatus(game, "ended");
        game.status = "ended";

        let params = {
          event: this.event._id,
          game: game._id,
        };
        let action = game.status === "live" ? "start" : "end";
        // console.log(`game:${action}`);
        if (this.allowEmit) {
          console.log(this.socket.emit(`game:${action}`, params));
        }
        // console.log(this.socket.emit(`game:${action}`,params));

        this.getEventGames();
      } catch (e) {
        //console.log(e);
        alert(e);
      } finally {
        this.$emit("hide_loader");
      }
    },
    async handleGoLive(game) {
      try {
        //Bind event id
        this.newEvent.event = this.event._id;
        let userConfirmed = await this.displayConfirmDialog({
          title: "Please confirm",
          body: "Do you want to launch this game? This action cannot be undone.",
        });
        if (!userConfirmed) {
          return;
        }
        this.$emit("show_loader");

        //Update status in DB
        // console.log(game);
        await GameModel.updateStatus(game, "live");
        game.status = "live";

        let params = {
          event: this.event._id,
          game: game._id,
        };
        let action = game.status === "live" ? "start" : "end";
        // console.log(`game:${action}`);
        // this.socket.emit(`game:${action}`,params);
        if (this.allowEmit) {
          this.socket.emit(`game:${action}`, params);
        }

        this.getEventGames();
      } catch (e) {
        //console.log(e);
        alert(e);
      } finally {
        this.$emit("hide_loader");
      }
    },
    async handleShowHideResults(game) {
      try {
        if (game.showResults) {
          this.socket.emit("game:showResult", { event: game.event, game: game.id });
        } else if (!game.showResults) {
          this.socket.emit("game:end", { event: game.event, game: game.id });
        }
        // this.$emit("show_loader");

        //Update status in DB
        let newStatus = game.showResults;
        await GameModel.updateShowHideResults(game, newStatus);

        //this.getEventGames();
      } catch (e) {
        game.showResults = !game.showResults;
        //console.log(e);
        alert(e);
      }
    },

    async saveGamePosition() {
      //Get all games and clone the object

      this.games = this.games.map((game, index) => {
        game.position = index;
        return game;
      });
      console.log(this.games);

      //Save status to DB
      try {
        this.$emit("show_loader");
        await Promise.all(
          this.games.map(async (game) => {
            await GameModel.updatePosition(game);
          })
        );
      } catch (err) {
        // debugger;
        console.log(err);
      } finally {
        // debugger;
        this.$emit("hide_loader");
      }
    },

    connectToSocket() {},
    disconnectFromSocket() {},
    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", (param) => {
        console.log("connected");
        console.log(param);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.event._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.event._id}`);
      });

      this.socket.on("intermission:start", () => {
        // alert("received")

        this.showIntermission = true;
      });
      this.socket.on("intermission:end", () => {
        // alert("receivedend")

        this.showIntermission = false;
      });

      // Reconnect
      this.socket.on("reconnect", () => {
        // reconnect
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.event._id}`,
          id: 1,
        });
      });

      this.socket.on("game:received", async (data) => {
        const self = this;
        if (!data || this.games.length === 0) {
          return;
        }
        // console.log(data);

        // try{
        //     this.allowEmit = false;
        //     await this.getEventGames();
        // }
        // catch(err){
        //     console.error(err);
        // }
        // finally{
        //     this.allowEmit = true;
        // }

        try {
          let games = [...self.games];
          // console.log(games);
          let elementIndex = -1;

          // alert(JSON.stringify(data));
          // console.log('*********',data);
          // debugger;

          let gameToUpdate = games.find((game, index) => {
            if (game._id === data._id) {
              elementIndex = index;
              return true;
            }
          });
          // console.log(elementIndex);

          if (gameToUpdate) {
            gameToUpdate.options = [...data.options];
            gameToUpdate.results.options = [...data.options];
            self.$set(gameToUpdate, "options", [...data.options]);
            self.$set(gameToUpdate.results, "options", [...data.options]);
            self.$set(self.games, elementIndex, gameToUpdate);
            self.games[elementIndex] = gameToUpdate;
            //this.getEventGames();
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.allowEmit = true;
        }

        // let games = [...self.games];
        // console.log(games);
        // let elementIndex = -1;
        // let gameToUpdate = games.find((game,index)=>{
        //     if(game._id === data._id){
        //         elementIndex = index;
        //         return true;
        //     }
        // });
        // console.log(elementIndex);

        // if(gameToUpdate){
        //     gameToUpdate.options = [...data.options]
        //     self.$set(self.games, elementIndex, gameToUpdate);
        //     this.getEventGames();
        // }
      });

      this.socket.on("game:ended", async (data) => {
        // alert('Game end');
        // this.getEventGames();
        // try{
        //     this.allowEmit = false;
        //     await this.getEventGames();
        // }
        // catch(err){
        //     console.error(err);
        // }
        // finally{
        //     this.allowEmit = true;
        // }

        const self = this;
        if (!data || this.games.length === 0) {
          return;
        }
        // alert('Game start');
        // this.getEventGames();
        try {
          // this.allowEmit = false;
          // await this.getEventGames();

          let games = [...self.games];
          // console.log(games);
          let elementIndex = -1;

          //alert(JSON.stringify(data));
          // console.log('*********',data);
          // debugger;

          let gameToUpdate = games.find((game, index) => {
            if (game._id === data.game) {
              elementIndex = index;
              return true;
            }
          });
          // console.log(elementIndex,gameToUpdate);
          // debugger;

          if (gameToUpdate) {
            // if(gameToUpdate.status === 'ended'){
            //     return;
            // }
            // gameToUpdate.options = [...data.options]
            // gameToUpdate.status = 'ended';

            // self.$set(gameToUpdate,'status','ended');
            // self.$set(self.games, elementIndex, gameToUpdate);

            //self.games[elementIndex] = gameToUpdate;
            //this.getEventGames();

            self.$set(self.games[elementIndex], "status", "ended");
            this.recomputeRating();
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.allowEmit = true;
        }
      });
      this.socket.on("game:live", async (data) => {
        const self = this;

        if (!data || this.games.length === 0) {
          return;
        }
        // alert('Game start');
        //alert(JSON.stringify(data));
        // debugger
        // this.getEventGames();
        try {
          // this.allowEmit = false;
          // await this.getEventGames();

          let games = [...self.games];
          // console.log(games);
          let elementIndex = -1;

          //alert(JSON.stringify(data));
          console.log("*********", data);
          // debugger;

          let gameToUpdate = games.find((game, index) => {
            if (game._id === data.game) {
              elementIndex = index;
              return true;
            }
          });
          console.log(elementIndex, gameToUpdate);
          // debugger;

          if (gameToUpdate) {
            // gameToUpdate.options = [...data.options]
            // if(gameToUpdate.status === 'live'){
            //     return;
            // }

            // gameToUpdate.status = 'live';
            // self.$set(gameToUpdate,'status','live');

            // self.games[elementIndex] = gameToUpdate;
            // self.$set(self.games, elementIndex, gameToUpdate);
            //this.getEventGames();

            // self.$set(gameToUpdate,'status','live');
            // self.$set(self.games, elementIndex, gameToUpdate);

            self.$set(self.games[elementIndex], "status", "live");
            this.recomputeRating();
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.allowEmit = true;
        }
      });

      console.log("subscribed");
    },
    unsubscribe() {},

    handleEndEventClick() {
      Swal.fire({
        customClass: {
          htmlContainer: "text-class-warning",
          confirmButton: "confirm-gamePending-warning",
          cancelButton: "confirm-gamePending-warning",
        },
        icon: "question",
        text: "You want to end the event",
        showCancelButton: true,
        cancelButtonText: "DISAGREE",
        confirmButtonText: "AGREE",
        confirmButtonColor: "#1976d2",
      }).then((response) => {
        if(response.isConfirmed) {
          this.showEndevent = true;
          this.socket.emit('page:reload', { event: this.event.id })
        } else {
          this.showEndevent = false;
        }

      });
    },


    async handleIntermissionClick() {
      try {
        let event = this.event;

        if (!event) {
          return;
        }
        let newStatus = this.showIntermission === true ? "live" : "pending";

        try {
          this.$emit("show_loader");
          // console.log(this.event.intermission);

          await GameModel.updateIntermissionStatus(event, newStatus);
          event.intermission = newStatus;

          let params = {
            event: this.event._id,
          };
          let action = this.showIntermission === true ? "start" : "end";
          console.log(`intermission:${action}`);
          // if(this.allowEmit === true){
          // alert(`intermission:${action}`);
          console.log(this.socket.emit(`intermission:${action}`, params));
          // this.allowEmit = false;
          // }

          //this.getEventGames();
          // try{
          //     this.allowEmit = false;
          //     //await this.getEventGames();
          // }
          // catch(err){
          //     console.error(err);
          // }
          // finally{
          //     this.allowEmit = true;
          // }
        } catch (e) {
          alert(e);
        } finally {
          this.$emit("hide_loader");
        }
      } catch (e) {
        console.error(e);
      }
    },

    async handleRatingsClick() {
      try {
        let eventRatingObj = this.eventRatingObj;
        if (!this.initialized || !eventRatingObj) {
          return;
        }

        let newStatus = this.showRatings === true ? "live" : "ended";

        try {
          this.$emit("show_loader");
          // console.log(this.event.rating);

          await GameModel.updateStatus(eventRatingObj, newStatus);
          eventRatingObj.status = newStatus;
          // this.showRatings = newValue;
          // this.$set(show)

          let params = {
            event: this.event._id,
            game: this.event.rating,
          };
          let action = this.showRatings === true ? "start" : "end";
          // console.log(`game:${action}`);

          // if(this.allowEmit === true){
          // alert('emit');
          this.socket.emit(`game:${action}`, params);
          // this.allowEmit = false;
          // }

          // await this.getEventGames();
        } catch (e) {
          alert(e);
        } finally {
          setTimeout(() => {
            this.allowEmit = true;
            this.$emit("hide_loader");
          }, 600);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {

    isGameFormValid() {
      /* Rules:
        1. Question lengh must be > 0
        2. Minimum answers = 2
        3. All answers must be filled
        4. At least one answer must be marked as correct if the event type is Trivia
      */

      // Validate question
      let question = this.newEvent.question;

      // Question required (not apply for quiz)
      if (typeof question !== "string" || question.trim().length === 0) {
        if (this.newEvent.type !== "quiz") {
          return false;
        }
      }

      //Validate question has valid answers
      if (this.validAnswers.length < 2) {
        return false;
      }

      let answerLengths = this.validAnswers.map((ans) => {
        return ans.answer.trim().length || 0;
      });

      let index = answerLengths.findIndex((el) => el === 0);
      if (index > -1) {
        return false;
      }

      //Check if trivia has at least one option marked as correct
      if (this.newEvent.type === "trivia" && this.totalOptionsMarkedCorrect <= 0 ) {
        return false;
      }

      return true;

    },
    validAnswers() {
      let answers = this.newEvent.options;
      if (!Array.isArray(answers)) {
        return [];
      }

      answers = answers.filter((el) => {
        if (el.delete == null || el.delete === false) {
          return el;
        }
      });

      return answers;
    },
    deletedAnswers() {
      let answers = this.newEvent.options;
      if (!Array.isArray(answers)) {
        return [];
      }

      answers = answers.filter((el) => {
        if (el._id != null && el.delete === true) {
          return el;
        }
      });

      return answers;
    },
    filteredGameResults() {
      let results = this.gameResults;
      if (!Array.isArray(results)) {
        return [];
      }

      results = results
        .map((res) => {
          try {
            switch (res.data.type.trim().toLowerCase()) {
              case "poll":
              case "trivia":
                return res.data;
            }
          } catch (e) {
            console.log(e);
          }
        })
        .filter((el) => el != null);
      return results;
    },
    totalOptionsMarkedCorrect() {
      if (!this.validAnswers) {
        return 0;
      }
      let total = this.validAnswers.reduce((total, option) => {
        if (option.isCorrect) {
          return total + 1;
        }
        return total;
      }, 0);
      return total;
    },
    totalLiveGames() {
      let games = this.games;
      let total = 0;
      if (!Array.isArray(games)) {
        return total;
      }

      total = games.reduce((total, game) => {
        if (game && game.status) {
          if (game.status === "live") {
            return (total += 1);
          }
        }
        return total;
      }, 0);

      return total;
    },
    eventRatingObj() {
      this.forceRecomputeCounter;
      let results = this.games;
      if (!Array.isArray(results)) {
        return null;
      }
      let eventRating = null;

      eventRating = results.find(
        (el) => el.type === "rating" && el._id === this.event.rating
      );
      return eventRating;
    },
  },
  watch: {
    event() {
      if (!this.event || this.initialized) {
        return;
      } else {
        this.initialize();
      }
    },
    "newEvent.question"() {
      if (typeof this.newEvent.question === "string") {
        //this.newEvent.question = this.newEvent.question.trim();
      }
    },
    "event._id"() {
      if (this.event._id) {
        this.getEventGames();
      }
    },
    "event.intermission"() {
      if (this.event.intermission) {
        this.showIntermission =
          this.event.intermission === "live" ? true : false;
      }
    },
    eventRatingObj() {
      if (!this.eventRatingObj) {
        return;
      }
      try {
        // this.allowEmit = false;
        let showRatings = this.eventRatingObj.status === "live" ? true : false;
        if (this.showRatings !== showRatings) {
          this.showRatings = showRatings;
        }
        // debugger;
      } catch (e) {
        console.log(e);
        this.showRatings = false;
      } finally {
        setTimeout(() => {
          this.allowEmit = true;
        }, 500);
      }
    },

    // showRatings(){
    //     if(!this.initialized || !this.allowEmit){
    //         return;
    //     }
    // }

    // async showRatings(newValue,oldValue){
    //     try{

    //         let eventRatingObj = this.eventRatingObj;
    //         if(!this.initialized || newValue === oldValue || !eventRatingObj || newValue == null || oldValue == null){
    //             return;
    //         }

    //             let newStatus = (newValue === true)?'live':'ended';

    //             try{

    //                 this.$emit('show_loader');
    //                 // console.log(this.event.rating);

    //                 await GameModel.updateStatus(eventRatingObj,newStatus);
    //                 eventRatingObj.status = newStatus;
    //                 // this.showRatings = newValue;
    //                 // this.$set(show)

    //                 let params = {
    //                     event: this.event._id,
    //                     game: this.event.rating
    //                 }
    //                 let action = (newValue === true)?'start':'end';
    //                 // console.log(`game:${action}`);

    //                 if(this.allowEmit === true){
    //                     // alert('emit');
    //                     this.socket.emit(`game:${action}`,params);
    //                     this.allowEmit = false;
    //                 }

    //                 // await this.getEventGames();
    //             }
    //             catch(e){
    //                 alert(e);
    //             }
    //             finally{
    //                 setTimeout(()=>{
    //                     this.allowEmit = true;
    //                     this.$emit('hide_loader');
    //                 },600);
    //             }

    //     }
    //     catch(e){
    //         console.error(e);
    //     }
    // },

    // async showIntermission(newValue,oldValue){
    // try{

    //     let event = this.event;

    //     if(!event || newValue == null || oldValue == null){
    //         return;
    //     }
    //         let newStatus = (this.showIntermission === true)?'live':'pending';

    //         try{
    //             this.$emit('show_loader');
    //             // console.log(this.event.intermission);

    //             await GameModel.updateIntermissionStatus(event,newStatus);
    //             event.intermission = newStatus;

    //             let params = {
    //                 event: this.event._id
    //             }
    //             let action = (this.showIntermission === true)?'start':'end';
    //             console.log(`intermission:${action}`);
    //             if(this.allowEmit === true){
    //                 // alert(`intermission:${action}`);
    //                 console.log(this.socket.emit(`intermission:${action}`,params));
    //                 this.allowEmit = false;
    //             }

    //             //this.getEventGames();
    //             try{
    //                 this.allowEmit = false;
    //                 await this.getEventGames();
    //             }
    //             catch(err){
    //                 console.error(err);
    //             }
    //             finally{
    //                 this.allowEmit = true;
    //             }
    //         }
    //         catch(e){
    //             alert(e);
    //         }
    //         finally{
    //             this.$emit('hide_loader');
    //         }

    // }
    // catch(e){
    //     console.error(e);
    // }
    // },

    // socket:{
    //     handler(newVal){
    //         if(newVal){
    //             this.subscribe();
    //         }
    //     },
    //     deep: true
    // }
  },
  async mounted() {
    // this.getEventGames();
    if (this.event) {
      this.initialized = false;
      this.initialize();
      this.$root.$on("changeShowresult", this.showresult);
    }
    //this.timer = setInterval(()=>{ this.getEventGames(false); console.log('Timer');}, 5000);

    // this.connectToSocket();

    // this.socket = io(CONSTANTS.SOCKET_SERVER_URL);

    //this.subscribe();
    // setTimeout(()=>{this.registerEvents();},1000);
  },
  beforeDestroy() {
    //this.disconnectFromSocket();
    if (this.socket) {
      this.socket.close();
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
