import EventModel from "../../../lib/EventModel";

export default {

  props: ["event"],

  data() {

    const {
      attendees
    } = this.event || {};

    const {
      total,
      checkin
    } = attendees || {};

    return {
      registrationsAndCheckinsOverTimeChartData: null,
      attendeesTotal: total || 0,
      attendeesCheckin: checkin || 0,
    };

  },

  computed: {

    checkinPercentage() {

      const {
        attendees
      } = this.event || {};

      const {
        total,
        checkin
      } = attendees || {};

      let totalUsers = this.event ? (total || 0) : 0;
      let checkinUsers = this.event ? (checkin || 0) : 0;

      let percentage = 0;

      if (checkinUsers === 0) {
        return percentage;
      }

      percentage = Math.round((checkinUsers * 100) / totalUsers);

      return percentage;

    },

    registrationsOverTimeChartData() {

      if (!this.registrationsAndCheckinsOverTimeChartData) {
        return null;
      }

      let registrationsOverTime = this.registrationsAndCheckinsOverTimeChartData.registrations;

      let aux = {};

      for (let i = 0; i < registrationsOverTime.length; i++) {
        let el = registrationsOverTime[i];
        if (el.datevalue) {
          aux[el.datevalue] = el?.total || 0;
        }
      }

      return aux;

    },

    checkinsOverTimeChartData() {

      if (!this.registrationsAndCheckinsOverTimeChartData) {
        return null;
      }

      let checkinsOverTime = this.registrationsAndCheckinsOverTimeChartData.checkins;

      let aux = {};

      for (let i = 0; i < checkinsOverTime.length; i++) {
        let el = checkinsOverTime[i];
        if (el.datevalue) {
          aux[el.datevalue] = el?.total || 0;
        }
      }

      return aux;

    }

  },
  methods: {

    async loadChartData() {

      if (this.event && (this.event.sandia || this.event._id)) {

        const data =  await EventModel.getRegistrationsAndCheckinOverTime( this.event.sandia || this.event._id );

        const {
          registrations,
          checkins
        } = data || {};

        this.registrationsAndCheckinsOverTimeChartData = { ...data };
        this.attendeesCheckin = (checkins || []).reduce((acc, el) => acc + el.total, 0);
        this.attendeesTotal = (registrations || []).reduce((acc, el) => acc + el.total, 0);

      }
    },

/*
    async loadChartData() {
      console.log('entro aqui')
      console.log('Event',await this.event )
      console.log('Sandia', this.event.sandia )
      console.log('Event ID', this.event._id)
      if (this.event && (this.event.sandia || this.event._id)) {
        this.registrationsAndCheckinsOverTimeChartData = {
          "registrations":[54],
          "checkins":[25]
        } //this.attendeesTotal.length , this.attendeesCheckinlength]

        console.log('Contiene todo',this.registrationsAndCheckinsOverTimeChartData)
      }
    },
*/
  },

  watch: {

    event() {
      this.loadChartData();
    }

  },

  mounted() {

    setTimeout( async () => {
      await this.loadChartData();
    }, 1000);

  }

};
